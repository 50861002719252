// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React from 'react';
import { useLocation } from 'react-router-dom';
import download from '../assets/download.png';
import TrackEvent from '../utils/events';

const BookDetails = () => {
    const location = useLocation();
    const { resource } = location.state || {};

    if (!resource) {
        return <p></p>;
    }

    return (
        <div className="relative mb-[30%] md:mb-[20%] lg:mb-[5%]">
            <div className="flex flex-col ml-[7%] xl:ml-[12%] mr-[5%] mt-[10%] md:mt-[7.5%] lg:mt-[5%]">
                <h1 className="font-lora font-bold text-[22px] md:text-[29px] lg:text-[36px]">{resource.name}</h1>

                <div className="grid lg:grid-cols-2 gap-[5%] my-[5%]">
                    {resource.volumes.map((volume) => (
                        <div>
                            <div className={`flex flex-row mb-4 lg:mb-6 items-center ${volume.file && volume.file.trim() !== "" ? 'justify-between' : 'justify-start'} space-x-4`}>
                                <p className='font-lora font-bold text-[#3C3E43] text-[20px]'>{volume.name}</p>
                                {volume.file !== "" ? (
                                    <a href={volume.file} download target="_blank" rel="noopener noreferrer" className="cursor-pointer" onClick={() => { TrackEvent(`${resource.id}_${volume.volumeId}_resource_downloaded`) }}>
                                        <img
                                            className="w-[29px] h-[29px]"
                                            src={download}
                                            alt="download icon"
                                        />
                                    </a>
                                ) : (
                                    <p className="text-[#FF0000] text-[16px] font-roboto">Coming soon</p>
                                )}
                            </div>
                            <div className='flex border-[1.5px] rounded-[9px]'>
                                <img
                                    className="w-[100%] h-auto"
                                    src={volume.image}
                                    alt="heart icon"
                                />
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}

export default BookDetails;