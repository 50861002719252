// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useState } from 'react';
import { saveContactForm } from '../service/api';
import success from '../assets/contact_us_success.svg';
import { useNavigate } from 'react-router-dom';
import TrackEvent from '../utils/events';

const ContactUs = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate(); // Move useNavigate here

    const validateForm = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!firstName) newErrors.firstName = 'First name is required.';
        if (!email) {
            newErrors.email = 'Email is required.';
        } else if (!emailRegex.test(email)) {
            newErrors.email = 'Email is not valid.';
        }
        if (!message) newErrors.message = 'Message is required.';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        try {
            const response = await saveContactForm({ firstName, lastName, email, message });
            console.log(response)
            if (response.success) {
                setIsModalOpen(true);
                TrackEvent('contact_us_form_submitted');
            } else {
                setIsModalOpen(true);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setIsModalOpen(true);
        }
    };

    const handleHomeClick = () => {
        navigate("/");
        setTimeout(() => {
            document.querySelector('.offerings-section')?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    return (
        <div className="ml-[5%] xl:ml-[12%] mt-[10%] md:mt-[5%] lg:mt-[3%]">
            <h1 className="font-lora font-bold text-[22px] md:text-[31px] lg:text-[40px] text-[#3C3E43]">Contact Us</h1>

            <p className="font-roboto font-normal text-[16px] md:text-[26px] lg:text-[36px] text-[#182A68] mt-[2%]">Share your message below.</p>
            <p className="font-roboto font-normal text-[16px] md:text-[26px] lg:text-[36px] text-[#182A68]">We will get back to you within 24 hours.</p>

            <form onSubmit={handleSubmit}>
                <div>
                    <p className="mt-[5%] lg:mt-[3%] font-roboto font-normal text-[12px] md:text-[18px] lg:text-[24px] text-[#3C3E43]">First Name *</p>
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-[80%] md:w-[60%] xl:w-[40%] h-[48px] md:h-[60px] lg:h-[74px] bg-white border-[1px] border-[#A2A4AA] rounded-[12px] box-border mt-2 lg:mt-3 px-4 font-roboto font-normal text-[12px] md:text-[18px] lg:text-[24px]"
                    />
                    {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
                </div>

                <div>
                    <p className="mt-6 lg:mt-8 font-roboto font-normal text-[12px] md:text-[18px] lg:text-[24px] text-[#3C3E43]">Last Name</p>
                    <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-[80%] md:w-[60%] xl:w-[40%] h-[48px] md:h-[60px] lg:h-[74px] bg-white border-[1px] border-[#A2A4AA] rounded-[12px] box-border mt-2 lg:mt-3 px-4 font-roboto font-normal text-[12px] md:text-[18px] lg:text-[24px]"
                    />
                </div>

                <div>
                    <p className="mt-6 lg:mt-8 font-roboto font-normal text-[12px] md:text-[18px] lg:text-[24px] text-[#3C3E43]">Email *</p>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-[80%] md:w-[60%] xl:w-[40%] h-[48px] md:h-[60px] lg:h-[74px] bg-white border-[1px] border-[#A2A4AA] rounded-[12px] box-border mt-2 lg:mt-3 px-4 font-roboto font-normal text-[12px] md:text-[18px] lg:text-[24px]"
                    />
                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                </div>

                <div>
                    <p className="mt-6 lg:mt-8 font-roboto font-normal text-[12px] md:text-[18px] lg:text-[24px] text-[#3C3E43]">Message *</p>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-[80%] md:w-[60%] xl:w-[40%] h-[140px] md:h-[200px] lg:h-[240px] bg-white border-[1px] border-[#A2A4AA] rounded-[12px] box-border mt-2 lg:mt-3 font-roboto font-normal text-[12px] md:text-[18px] lg:text-[24px] p-2"
                    />
                    {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                </div>

                <div className="mt-6 lg:mt-8 inline-flex justify-start">
                    <button type="submit" className="bg-[#213A8F] py-4 px-12 rounded-[100px] cursor-pointer">
                        <p className="text-white text-center font-roboto font-medium text-[12px] md:text-[18px] lg:text-[24px]">Send with a smile</p>
                    </button>
                </div>
            </form>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-8 w-[80%] lg:max-w-md">
                        {/* Icon */}
                        <div className="flex justify-center mb-4">
                            <img
                                className="w-[175px] h-[65px]"
                                src={success}
                                alt="Success"
                            />
                        </div>

                        {/* Thank You Message */}
                        <div className="text-center">
                            <h2 className="text-2xl font-bold text-[#3C3E43]">Thank you!</h2>
                            <p className="text-lg text-gray-600 mt-2">
                                We have received your details. We will get back to you within 24 hours.
                            </p>
                        </div>

                        <div className="mt-6 flex flex-col space-y-4">
                            <button
                                onClick={handleHomeClick}
                                className="w-full bg-[#213A8F] text-white py-3 rounded-lg font-medium"
                            >
                                Home
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContactUs;
