// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import images from "../utils/images";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import TrackEvent from "../utils/events";

const Offering = () => {

    const { category } = useParams();
    const defaultSection = data.find(item => item.categoryId === category)?.sections[0]?.name;
    const [selectedSection, setSelectedSection] = useState(defaultSection);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const scrollContainerRef = useRef(null);

    const checkForOverflow = () => {
        const container = scrollContainerRef.current;
        if (container) {
            setShowRightArrow(container.scrollWidth > container.clientWidth);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setSelectedSection(defaultSection);
        checkForOverflow();
        window.addEventListener('resize', checkForOverflow);
        return () => window.removeEventListener('resize', checkForOverflow);
    }, [category]);

    const scroll = (direction) => {
        const container = scrollContainerRef.current;
        if (container) {
            const scrollAmount = container.clientWidth / 2;
            container.scrollBy({
                left: direction === 'right' ? scrollAmount : -scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    const handleScroll = () => {
        const container = scrollContainerRef.current;
        if (container) {
            setShowLeftArrow(container.scrollLeft > 0);
            setShowRightArrow(
                container.scrollLeft < container.scrollWidth - container.clientWidth
            );
        }
    };

    const currentCategory = data.find(item => item.categoryId === category);

    const currentCategorySections = currentCategory.sections;

    const currentCategoryName = currentCategory.name;

    const currentSectionData = currentCategorySections.find(section => section.name === selectedSection);

    return (
        <div className="relative">
            <div className="flex flex-col ml-[7%] xl:ml-[12%] mt-[10%] lg:mt-[5%]">
                <h1 className="font-lora font-bold text-[22px] md:text-[29px] lg:text-[36px]">{currentCategoryName}</h1>

                <div className="relative flex items-center mt-[7%] md:mt-[5%] space-x-0 lg:space-x-2 xl:space-x-3">
                    {showLeftArrow && (
                        <button
                            onClick={() => scroll('left')}
                            className="absolute left-0 z-10 p-2 bg-white rounded-full shadow-md"
                        >
                            <ChevronLeft className="w-6 h-6 text-[#213A8F]" />
                        </button>
                    )}
                    <div
                        ref={scrollContainerRef}
                        className="flex overflow-x-auto scrollbar-hide space-x-2 p-2"
                        onScroll={handleScroll}
                    >
                        {currentCategorySections.map(({ name, sectionId }) => (
                            <button
                                key={name}
                                onClick={() => {
                                    setSelectedSection(name)
                                    TrackEvent(`${sectionId}_feature_clicked`);
                                }
                                }
                                className={`flex-shrink-0 text-[14px] md:text-[16px] lg:text-[20px] px-3 lg:px-4 py-1 rounded-[44px] font-roboto font-bold transition-colors duration-300 ${selectedSection === name ? 'bg-[#213A8F] text-white' : 'text-[#213A8F]'
                                    }`}
                            >
                                <span>{name}</span>
                            </button>
                        ))}
                    </div>
                    {showRightArrow && (
                        <button
                            onClick={() => scroll('right')}
                            className="absolute right-0 z-10 p-2 bg-white rounded-full shadow-md"
                        >
                            <ChevronRight className="w-6 h-6 text-[#213A8F]" />
                        </button>
                    )}
                </div>
            </div>

            <div className="flex lg:hidden relative mb-[100%] mt-[7%] md:mt-[5%]">
                <div className="flex flex-col justify-between items-start relative">
                    <div className="ml-[2%] md:ml-[5%]">
                        <ul className="list-none">
                            {currentSectionData.features.map((feature, index) => (
                                <li key={index} className="flex items-center pl-8 mb-4">
                                    <span
                                        className="h-[11px] w-[11px] md:h-[13.5px] md:w-[13.5px] bg-no-repeat bg-contain"
                                        style={{ backgroundImage: `url(${images.offeringsPoint})` }}
                                    />
                                    <span className="ml-4 font-roboto font-normal text-[#3C3E43] text-[14px] md:text-[20px] leading-4 md:leading-6">
                                        {feature}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <img
                        src={images.offeringsMobileBg}
                        alt={`${selectedSection} Image`}
                        className="w-full h-auto"
                    />
                    <img
                        src={currentSectionData.image}
                        alt={`${selectedSection} Image`}
                        className="absolute top-[35%] w-[90%] ml-auto mr-auto left-0 right-0 h-auto object-contain"
                    />
                </div>
            </div>

            <div className="hidden lg:flex relative mb-[30%]">
                <div className="flex flex-row w-full justify-between items-start z-10 ml-[7%] xl:ml-[12%] mt-[5%] relative">
                    <div className="flex-1">
                        <ul className="list-none">
                            {currentSectionData.features.map((feature, index) => (
                                <li key={index} className="flex items-center pl-8 mb-4">
                                    <span
                                        className="h-[17.5px] w-[17.5px] bg-no-repeat bg-contain"
                                        style={{ backgroundImage: `url(${images.offeringsPoint})` }}
                                    />
                                    <span className="ml-4 font-roboto font-normal text-[#3C3E43] text-[20px] xl:text-[22px] leading-6 xl:leading-8">
                                        {feature}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex-1">
                        <img
                            src={currentSectionData.image}
                            alt={`${selectedSection} Image`}
                            className="w-[60%] h-auto object-contain mt-[-5%]"
                        />
                    </div>
                </div>

                <img
                    src={images.offeringsBg}
                    alt={`${selectedSection} Image`}
                    className="w-full h-auto object-contain absolute top-[35%] z-0"
                />
                <div className="h-[100%]"></div>
            </div>
        </div >
    )
}

export default Offering;

const data = [
    {
        "categoryId": "money_tracking",
        "name": "Money Tracking",
        "sections": [
            {
                "sectionId": "money_tracking_bank_accounts",
                "name": "Bank Accounts",
                "features": [
                    "No more juggling multiple apps",
                    "No more paper passbooks",
                    "All transactions searchable",
                    "Bank wise & unified view",
                    "Visual savings & Spend trends",
                    "Spend categorization"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/bank_accounts.png"
            },
            {
                "sectionId": "money_tracking_nps",
                "name": "National Pension System",
                "features": [
                    "Scheme wise holdings",
                    "Search and filter contributions",
                    "Visual performance trends",
                    "Notifications to be mindful"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/nps.png"
            },
            {
                "sectionId": "money_tracking_epf",
                "name": "Employee Provident Fund",
                "features": [
                    "Balance with employee and employer split",
                    "Employer and employee contributions",
                    "Search and filter contributions",
                    "Past employer data",
                    "Visual trends and performance"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/epf.png"
            },
            {
                "sectionId": "money_tracking_mf",
                "name": "Mutual Funds",
                "features": [
                    "Balance and cumulative gain/loss over last N statements",
                    "Visual performance trends",
                    "Fund wise breakup of balance, invested amount, gain/loss"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/mf.png"
            },
            {
                "sectionId": "money_tracking_fd_rd",
                "name": "FD/ RD",
                "features": [
                    "Your digital diary to track FD/ RD",
                    "Digital alerts on maturity",
                    "Minimize bank visits",
                    "Minimize juggling multiple apps",
                    "Recommendations on maturity to boost returns"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/fd.png"
            }
        ]
    },
    {
        "categoryId": "higher_returns",
        "name": "Higher Return",
        "sections": [
            {
                "sectionId": "higher_returns_fd",
                "name": "High Interest Rate FD",
                "features": [
                    "Open high interest rate FDs",
                    "AAA rated providers with 1-2% higher rate over most banks",
                    "Trusted by over 5 lakh depositors",
                    "Fully digital experience",
                    "Support staff available as needed",
                    "UPI, RTGS supported"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/high_interest_rate_fd.png"
            },
            {
                "sectionId": "higher_returns_fd_recommendations",
                "name": "Personalized Recommendations",
                "features": [
                    "Reminders upcoming investment decisions and presenting actionable steps",
                    "Customize suggestions according to risk appetite",
                    "Suggestion to optimized allocation for maximum earnings",
                    "Personalized recommendations for reinvestment options",
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/personalised_recommendations.png"
            }
        ]
    },
    {
        "categoryId": "more_savings",
        "name": "More Savings",
        "sections": [
            {
                "sectionId": "more_savings_savings_trends",
                "name": "Savings Trends",
                "features": [
                    "Track Your Savings Growth",
                    "Spot Key Savings Opportunities",
                    "Stay Ahead with Real-Time Savings Insights",
                    "Optimize Savings Habits",
                    "Make Your Money Work for You",
                    "Monitor and Maximize"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/savings_trends.png"
            },
            {
                "sectionId": "more_savings_income_trends",
                "name": "Income Trends",
                "features": [
                    "Understand Your Earning Patterns",
                    "Income Insights for Smarter Planning",
                    "Track Earnings for Better Financial Control",
                    "Predict Future Income",
                    "Visualize Your Income Progress",
                    "Maximize Your Income Potential"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/income_trends.png"
            },
            {
                "sectionId": "more_savings_spend_trends",
                "name": "Spend Trends",
                "features": [
                    "Track Spending to Save More",
                    "Spot High-Spend Areas",
                    "Monitor and Manage",
                    "Control Unnecessary Expenses",
                    "Get a Clear Picture of Your Expenses",
                    "Discover Hidden Savings Potential"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/spend_trends.png"
            },
            {
                "sectionId": "more_savings_nps_trends",
                "name": "NPS Trends",
                "features": [
                    "Track NPS growth for a secure future",
                    "Stay informed on pension returns",
                    "Visualize your retirement fund growth",
                    "Boost your NPS investments",
                    "Monitor your NPS journey",
                    "Maximize pension growth"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/nps_trends.png"
            },
            {
                "sectionId": "more_savings_epf_trends",
                "name": "EPF Trends",
                "features": [
                    "Track your EPF savings over time",
                    "Stay on top of your EPF",
                    "Visualize your EPF growth",
                    "Optimize your provident fund",
                    "Prepare for retirement smarter"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/epf_trends.png"
            },
            {
                "sectionId": "more_savings_mutual_fund_trends",
                "name": "Mutual Fund Trends",
                "features": [
                    "Track your MF investments over time",
                    "Stay on top of MF investment performance",
                    "Visualize MF portfolio growth",
                    "Track your MF investment XIRR"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/mf_trends.png"
            }
        ],
    },
    {
        "categoryId": "avoid_losses",
        "name": "Avoid Losses",
        "sections": [
            {
                "sectionId": "avoid_losses_alert",
                "name": "Alert",
                "features": [
                    "Stay Alert, Avoid duplicate charges",
                    "Stay Alert, catch missing credit transactions like EPF credit",
                    "Stay Alert, Detect large transactions to unknown/suspicious vendors",
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/alert.png"
            },
            {
                "sectionId": "avoid_losses_maturity_notification",
                "name": "Maturity Notification",
                "features": [
                    "Never Miss a Maturity Date",
                    "Timely Reminders for Smarter Decisions",
                    "Maturity Alerts for Optimized Reinvestment",
                    "Stay on Track with Maturity Notifications"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/maturity_notification.png"
            },
            {
                "sectionId": "avoid_losses_xirr_notification",
                "name": "XIRR Notification",
                "features": [
                    "Track Real Returns with XIRR Alerts",
                    "Know Your Investment Performance",
                    "XIRR Notifications for Better Financial Insights",
                    "Maximize Gains with XIRR Updates"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/xirr_notification.png"
            },

        ]
    },
    {
        "categoryId": "better_financially_prepared",
        "name": "Better Financially Prepared",
        "sections": [
            {
                "sectionId": "better_financially_prepared_retirement",
                "name": "Retirement",
                "features": [
                    "Set your retirement goal correctly",
                    "Track progress towards that goal",
                    "Recommendations to achieve retirement savings needed"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/retirement.png"
            },
            {
                "sectionId": "better_financially_prepared_college_fund",
                "name": "College Fund",
                "features": [
                    "Set your savings goal correctly",
                    "Track progress towards that goal",
                    "Recommendations to achieve education savings needed"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/college.png"
            },
            {
                "sectionId": "better_financially_prepared_emergency_fund",
                "name": "Emergency Fund",
                "features": [
                    "Set your emergency fund correctly",
                    "Track progress towards that goal",
                    "Recommendations to achieve emergency fund needed"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/emergency.png"
            },
            {
                "sectionId": "better_financially_prepared_buy_home",
                "name": "Buy Home",
                "features": [
                    "Set your home buying goal correctly",
                    "Track progress towards that goal",
                    "Recommendations to achieve down-payment needed"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/buy_home.png"
            },
            {
                "sectionId": "better_financially_prepared_prepay_loan",
                "name": "Prepay Loan",
                "features": [
                    "Set your loan prepayment goal correctly",
                    "Track progress towards that goal",
                    "Recommendations to achieve loan prepayment on time"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/prepay_loan.png"
            },
            {
                "sectionId": "better_financially_prepared_wedding_fund",
                "name": "Wedding Fund",
                "features": [
                    "Set your wedding fund goal correctly",
                    "Track progress towards that goal",
                    "Recommendations to achieve wedding fund needed"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/wedding_fund.png"
            },
            {
                "sectionId": "better_financially_prepared_parent_care_fund",
                "name": "Parent Care Fund",
                "features": [
                    "Set your elder care goal correctly",
                    "Track progress towards that goal",
                    "Recommendations to achieve parent care fund needed"
                ],
                "image": "https://finurja-website-images.s3.ap-south-1.amazonaws.com/offerings/parent_care.png"
            }
        ]
    },
]