// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import rightArrow from '../assets/right_arrow.png';
import { fetchBlog, fetchBlogs } from '../service/api';
import { Link } from 'react-router-dom';
import heart from '../assets/heart.png';

const Blog = () => {

    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        const loadBlog = async () => {
            try {
                const fetchedBlog = await fetchBlog(id);
                setBlog(fetchedBlog);
            } catch (error) {
                console.error('Error fetching blog:', error);
            } finally {
                setLoading(false);
            }
        };

        const loadInitialBlogs = async () => {
            try {
                const initialBlogs = await fetchBlogs(3, 0);
                setBlogs(initialBlogs);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        loadInitialBlogs();
        loadBlog();
    }, [id]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(date);
    };

    if (loading) {
        return <p className="text-center">Loading...</p>;
    }

    if (!blog) {
        return <p className="text-center">No blog found</p>;
    }

    return (
        <div className="flex flex-col mx-[12%] mt-[5%]">
            <div className='flex flex-row items-center space-x-4'>
                <Link to={"/blogs"}>
                    <h1 className="font-lora font-bold text-[36px] text-[#3C3E43]">Micro Blogs</h1>
                </Link>
                <img
                    className="h-[29px] w-[17px]"
                    src={rightArrow}
                />
                {blog ? <h2 className="font-lora font-bold text-[36px] text-[#3C3E43]">{blog.title}</h2> : <p>Loading...</p>}
            </div>

            <p className="font-roboto font-normal text-[28px] text-[#3C3E43] mt-[3%]">{formatDate(blog.createdAt)}</p>

            <h className="mt-[3%] font-roboto font-bold text-[48px] text-[#3C3E43]">{blog.title}</h>

            <p className='mt-[2%] font-roboto font-normal text-[30px] text-[#3C3E43]'>{blog.body}</p>

            <a href={blog.sourceLink} target="_blank" rel="noopener noreferrer">
                <h1 className='mt-[1.5%] font-roboto font-bold text-[28px] text-[#2B4CBA]'>
                    Read more at {blog.source}
                </h1>
            </a>

            <img
                className="w-auto max-w-full h-auto mt-[2%]"
                src={blog.imageURL}
                alt={blog.title}
            />

            <hr className="my-[3%] border-t-1 border-[#A2A4AA]" />

            <h className='font-roboto font-bold text-[#3C3E43] text-[28px]'>Read other blogs</h>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 my-[3%]">
                {blogs.map((blog) => (
                    <Link to={`/blog/${blog.id}`}>
                        <div key={blog.id} className="bg-white rounded-[12px] shadow-blog p-3 flex flex-col h-full">
                            <p className="font-roboto font-normal text-[14px] text-[#3C3E43] mb-2">{formatDate(blog.createdAt)}</p>
                            <img
                                className="w-full h-auto object-cover mb-3 flex-grow"
                                src={blog.imageURL}
                                alt={blog.title}
                            />
                            <h1 className="font-roboto font-medium text-[20px] text-[#3C3E43] mb-3">{blog.title}</h1>
                            <div className="flex flex-row justify-between w-full bg-[#FAFAFA] border-[#E4E5E7] border-[1.15px] rounded-[12px] p-4">
                                <p className="font-roboto font-normal text-[14px] text-[#3C3E43]">{blog.views} views</p>
                                <div className="flex flex-row space-x-1 items-center">
                                    <p className="font-roboto font-normal text-[14px] text-[#3C3E43]">{blog.likes}</p>
                                    <img
                                        className="w-[23px] h-auto"
                                        src={heart}
                                        alt="heart icon"
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>

                ))}
            </div>

            <Link to={"/blogs"}>
                <p
                    className='w-full text-center font-roboto text-[#213A8F] text-[28px] underline cursor-pointer mt-4'
                >
                    View All
                </p>
            </Link>

        </div>
    )
}

export default Blog;