// Copyright © 2022, 2023, 2024 Artha Astra Inc.
// All rights reserved.


import images from "../utils/images";
const About = () => {
    return (
        <div className="flex flex-col">
            <div className="flex flex-col xl:ml-[12%] xl:mr-[10%] lg:mt-[5%] mt-[7%] mx-[7%]">
                <h1 className="font-lora font-bold text-[22px] md:text-[29px] lg:text-[36px]">About
                    <span className="text-[#2B4CBA]"> finurja</span>
                </h1>
                <div className="flex flex-col xl:flex-row mt-[5%]">
                    <div className="flex-1 order-2 xl:order-1">
                        <img
                            src={images.about}
                            alt="About"
                            className="w-full xl:w-auto mt-5 xl:mt-0"
                        />
                    </div>
                    <div className="flex-1 flex flex-col pl-0 xl:pl-[5%] justify-between order-1 xl:order-2">
                        <p className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px]">
                            Financial Wellness is still an elusive activity for many affluents in India. There are many reasons for it, and lack of suitable financial products isn't one. One which stands out for us is the ‘one size fits all’, or at times ‘personalized for the namesake’ approach in financial services industry. The industry needs a rejig and we want to be the ones who do that.
                        </p>
                        <p className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px] mt-4 xl:mt-8">
                            We want to be the ones to provide the best money management app designed from the ground up for conservative affluents in India.
                        </p>
                        <p className="font-roboto font-normal text-[14px] md:text-[19px] lg:text-[24px] mt-4 xl:mt-8">
                            Think of us as "Fitbit for your money health"!
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full bg-half-split-about-us mt-[10%]">
                <h1 className="font-lora font-bold text-[14px] md:text-[20px] lg:text-[26px] xl:text-[36px] text-center mx-[3%] md:mx-[10%] lg:mx-[15%] mt-[10%] lg:mt-[7%]">We're here to
                    <span className="text-[#2B4CBA]"> transform</span>
                    <span> your financial journey and enhance your financial life</span>
                </h1>
                <div className="grid grid-cols-2 lg:grid-cols-4 mt-[10%] lg:mt-[5%] mx-[4%] gap-2 lg:mb-[20%]">
                    {cardData.map((card, index) => (
                        <div
                            key={index}
                            className="flex flex-col bg-white rounded-[12px] border-[#E2E7F8] border border-solid px-4 pt-4 pb-6 lg:px-6 lg:pt-6 lg:pb-10 shadow-aboutUs"
                        >
                            <img className="w-[50px] md:w-[75px] xl:w-[100px] h-auto" src={card.image} alt="About" />
                            <h className="mt-2 lg:mt-3 font-roboto font-bold text-[14px] md:text-[20px] xl:text-[26px] text-[#3C3E43] mb-[15%] lg:mg-[20%]">
                                {card.title} <span className="text-[#2B4CBA]">{card.highlightedTitle}</span>
                            </h>
                            <div className="flex-grow"></div>
                            <p className="font-roboto font-normal text-[13px] md:text-[18px] xl:text-[24px] text-[#55575D]">
                                {card.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}

export default About;


const cardData = [
    {
        image: images.security,
        title: "Bank like",
        highlightedTitle: "security",
        description: "We safeguard your data using 256-bit industry-standard encryption.",
    },
    {
        image: images.trust,
        title: "Trusted by Indians like",
        highlightedTitle: "you",
        description: "Specially designed to meet your unique needs and empower you.",
    },
    {
        image: images.support,
        title: "On-Demand",
        highlightedTitle: "Support",
        description: "Access the support you need, exactly when you need it.",
    },
    {
        image: images.charges,
        title: "No surprise charges or",
        highlightedTitle: "ads",
        description: "No hidden fees or annoying ads. What you see is what you get.",
    },
];
